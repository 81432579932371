import React, { useState } from 'react';
import {
    CardsContainer,
    SubMenusContainer,
    Text,
    MenuContainer,
    WelcomeText,
    Body,
    NoModuleContainer,
    NoModuleText,
} from './styles';
import { useTypedSelector } from '../../Storage/rootReducer';
import ModuleCard from '../../Components/Modulos';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { IModuloDetalhes } from '../../Interfaces/IModulo';
import SubModuleList from '../../Components/SubModulos';

const Home: React.FC = () => {
    const screenPixelRatio = globalThis.devicePixelRatio;
    const urlCdnImg = sessionStorage.getItem('CAPNEGOCIOS_ENV_URL_CDN_IMG');

    const modulosUsuario = useTypedSelector(store => store.User.modulosUsuario);
    const modulosAtivos =
        modulosUsuario?.modulosCapNegocio
            .filter(modulo => modulo.isAtivo)
            .slice()
            .sort((modA, modB) => modA.ordemModulo - modB.ordemModulo) ?? [];

    const modulosInativos =
        modulosUsuario?.modulosCapNegocio
            .filter(modulo => !modulo.isAtivo)
            .slice()
            .sort((modA, modB) => modA.ordemModulo - modB.ordemModulo) ?? [];

    const displayListaModulos: IModuloDetalhes[] = [
        ...modulosAtivos,
        ...modulosInativos,
    ];

    const [moduloSelecionado, setModuloSelecionado] = useState<number>(-1);
    const semModuloAtivo = displayListaModulos.filter(item => item.isAtivo);

    const dataTenantUuid = sessionStorage.getItem('DATA_TENANT_UUID');
    const dataDomainUuid = sessionStorage.getItem('DATA_DOMAIN_UUID');

    React.useEffect(() => {
        // eslint-disable-next-line func-names
        (function () {
            const s = document.createElement('script');
            s.src =
                'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
            s.setAttribute(
                'data-tenant-uuid',
                dataTenantUuid ?? '8b4b5809-7671-41d9-8d18-1c0ee37c0f65',
            );
            s.setAttribute(
                'data-domain-uuid',
                dataDomainUuid ?? '3fac3eb8-4ac3-4b11-ad9b-37007bc47a92',
            );
            s.setAttribute('data-backend-url', 'https://app.securiti.ai');
            s.setAttribute('data-skip-css', 'false');
            s.defer = true;
            // eslint-disable-next-line camelcase
            const parent_node = document.head || document.body;
            parent_node.appendChild(s);
        })();
    }, []);

    function obterUrlBaseModulo(
        moduloSelecionadoIndex: number,
        listaModulos: IModuloDetalhes[],
    ): string {
        const urlModulo = listaModulos?.[moduloSelecionadoIndex]?.urlModulo;

        if (urlModulo?.toLowerCase()?.includes('pagamentodosorteado')) {
            return urlModulo;
        }

        return `${urlModulo}/#`;
    }

    return (
        <>
            <Header />
            <Body>
                <WelcomeText screenPixelRatio={screenPixelRatio}>
                    Seja bem-vindo(a) ao portal{' '}
                    <span
                        style={{
                            color: '#636363',
                            fontWeight: 600,
                            fontSize: 'inherit',
                        }}
                    >
                        CAPNegócios
                    </span>
                </WelcomeText>
                {!(displayListaModulos.length <= 0) && (
                    <Text screenPixelRatio={screenPixelRatio}>
                        Selecione o módulo desejado
                    </Text>
                )}
                <MenuContainer>
                    {displayListaModulos.length <= 0 ? (
                        <NoModuleContainer>
                            <NoModuleText>
                                O Acesso ao(s) módulo(s) ainda não está
                                disponível a você. Favor entrar em contato com a
                                Capemisa. Desculpe o transtorno :(
                            </NoModuleText>
                        </NoModuleContainer>
                    ) : (
                        <>
                            <CardsContainer>
                                {displayListaModulos?.map((modulo, index) => (
                                    <ModuleCard
                                        key={modulo?.ordemModulo}
                                        name={modulo?.nomeModulo}
                                        baseLink={modulo?.urlModulo}
                                        iconUrl={`${urlCdnImg}${modulo.urlIcone}`}
                                        isSelected={moduloSelecionado === index}
                                        isActive={modulo.isAtivo}
                                        isNew={modulo.novo}
                                        selectModulo={() =>
                                            setModuloSelecionado(index)
                                        }
                                    />
                                ))}
                            </CardsContainer>
                            <SubMenusContainer>
                                <SubModuleList
                                    semModuloAtivo={
                                        !semModuloAtivo.length
                                            ? 'O acesso ao(s) módulo(s) ainda não está(ão) disponível(veis) a você. Favor entrar em contato com a CAPEMISA. Desculpe-nos pelo transtorno. =)'
                                            : undefined
                                    }
                                    subMenus={displayListaModulos?.[
                                        moduloSelecionado
                                    ]?.subMenuCapNegocio.sort(
                                        (a, b) =>
                                            a.ordemSubMenu - b.ordemSubMenu,
                                    )}
                                    moduloSelecionado={moduloSelecionado}
                                    baseUrl={obterUrlBaseModulo(
                                        moduloSelecionado,
                                        displayListaModulos,
                                    )}
                                />
                            </SubMenusContainer>
                        </>
                    )}
                </MenuContainer>
            </Body>
            <Footer />
        </>
    );
};

export default Home;
