/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosInstance } from 'axios';
import { IErrorAPI, IResultAPI } from '../../Interfaces/API';

const devAmbiente =
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test';

function obterTokenApi(): string {
    if (devAmbiente) {
        return sessionStorage.getItem('TOKEN') ?? '';
    }

    return (
        globalThis.location.href.split('/#id_token=')?.[1] ??
        sessionStorage.getItem('TOKEN')
    );
}

const criarAxiosInstance = (tokenApi?: string): AxiosInstance => {
    const axiosInstance = axios.create({
        headers: {
            'Cache-Control': 'no-store',
            Authorization: `Bearer ${tokenApi ?? obterTokenApi()}`,
        },
    });

    axiosInstance.interceptors.request.use(
        config => {
            if (devAmbiente) {
                const logger = {
                    json: config.data,
                    jsonString: JSON.stringify(config.data),
                    url: config.url,
                    method: config.method,
                };

                console.groupCollapsed('API CALL:');
                console.timeStamp('TIMESTAMP API CALL');
                console.warn(logger);
                console.groupEnd();
            }
            return config;
        },
        e => {
            if (devAmbiente) {
                const logger = e.message as string;
                console.groupCollapsed('API CALL ERROR:');
                console.timeStamp('TIMESTAMP API CALL ERROR');
                console.error(logger);
                console.groupEnd();
            }
            return Promise.reject<IErrorAPI>({
                erroRequisicao: true,
                erroDesconhecido: true,
                status: NaN,
                statusTexto: '',
                mensagem: e.message as string,
                qtdErros: 1,
            } as IErrorAPI);
        },
    );

    axiosInstance.interceptors.response.use(
        response => {
            if (devAmbiente) {
                const logger = {
                    statusText: response.statusText,
                    status: response.status,
                    response: response.data,
                    url: response.config.url,
                };
                console.groupCollapsed('API RESPONSE:');
                console.timeStamp('TIMESTAMP API RESPONSE');
                console.warn(logger);
                console.groupEnd();
            }
            return response;
        },
        e => {
            if (devAmbiente) {
                const logger = !e.response
                    ? (e.message as string)
                    : {
                          statusText: e.response.statusText,
                          status: e.response.status,
                          response: e.response.data,
                          url: e.response.config.url,
                      };
                console.groupCollapsed('API RESPONSE ERROR:');
                console.timeStamp('TIMESTAMP API RESPONSE ERROR');
                console.error(logger);
                console.groupEnd();
            }

            const errosDobackend: string[] | undefined =
                (e.response.data.errors
                    ? Object.keys(e.response.data.errors)
                          .map(key => e.response.data.errors[key] as string[])
                          .reduce(
                              (errosTotal, errosAtual) => [
                                  ...errosAtual,
                                  ...errosTotal,
                              ],
                              [],
                          )
                    : undefined) || e.response.message;
            if (errosDobackend) {
                return Promise.reject<IErrorAPI>({
                    erroRequisicao: false,
                    erroDesconhecido: false,
                    status: e.response.status as number,
                    statusTexto: e.response.statusText as string,
                    mensagem: `\r\n${errosDobackend
                        .map(erro => `\u2022 ${erro}\r\n`)
                        .join('')}`,
                    qtdErros: errosDobackend.length,
                } as IErrorAPI);
            }
            return Promise.reject<IErrorAPI>({
                erroRequisicao: false,
                erroDesconhecido: true,
                status: e.response.status as number,
                statusTexto: e.response.statusText as string,
                qtdErros: 1,
                mensagem: `Erro desconhecido: ${
                    (e.response
                        ? typeof e.response.data === 'string'
                            ? e.response.data
                            : e.response.statusText ?? e.response.status
                        : e.message) as string
                }`,
            } as IErrorAPI);
        },
    );

    return axiosInstance;
};

export const CreateAxiosInstace = criarAxiosInstance;
export const GetToken = obterTokenApi;

export const callApiWithAxios = async <T = unknown>(
    funcCallAPI: () => Promise<T>,
): Promise<IResultAPI<T>> => {
    try {
        return {
            success: true,
            data: await funcCallAPI(),
        };
    } catch (e) {
        return {
            success: false,
            data: e as IErrorAPI,
        };
    }
};
