import React from 'react';
import { Container, Link } from './styles';

const Footer: React.FC = (): React.ReactElement => {
    const linkPortalPrivacidade =
        sessionStorage.getItem(
            'CAPNEGOCIOS_ENV_PORTAL_PRIVACIDADE_CAPEMISA_URL',
        ) ?? '';
    return (
        <Container>
            <Link href={linkPortalPrivacidade}>Portal de Privacidade</Link>
        </Container>
    );
};

export default Footer;
