/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { CreateAxiosInstace, GetToken } from './utils.service';

const Token = GetToken();

export const defaultUrlPortalSorteado = () =>
    `${sessionStorage.getItem('PORTAL_SORTEADO_API_URL')}/api`;

export const PortalSorteadoAPI = CreateAxiosInstace(Token);
