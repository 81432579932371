/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 4%;
    display: flex;
    background-color: #d2d2d2;
`;

export const Link = styled.a`
    display: flex;
    width: 50%;
    text-decoration: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #707070;
`;
