/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable camelcase */
import React from 'react';
import { Provider } from 'react-redux';
import Router from './Routes/index';

import { Container } from './styles';

import store from './Storage';
import CreateGlobalStyle from './Styles/global';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Container>
                <Router />
                <CreateGlobalStyle />
            </Container>
        </Provider>
    );
};

export default App;
