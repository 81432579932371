import { createGlobalStyle } from 'styled-components';

const CreateGlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        border: 0;
        padding: 0;
        outline: 0;
        font: inherit;
        font-size: 14px;
        box-sizing: border-box;
        font-family: 'Segoe UI';
        vertical-align: baseline;
    }
    body {
        min-height: 100vh;
        background: #FFF;
        -webkit-font-smoothing: antialiased;
    }

    /* Custom scroll styles */
    ::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }

    /* Linha do scroll */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Barra do scroll */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #e27a04;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: black;
    }
    };
`;
export default CreateGlobalStyle;
const customMediaQuery = (maxWidth: number): string =>
    `@media (max-width: ${maxWidth}px)`;

export const mediaQuery = {
    custom: customMediaQuery,
    grande: customMediaQuery(2560),
    medio: customMediaQuery(1920),
    pequeno: customMediaQuery(1366),
    superPequeno: customMediaQuery(1024),
};
