import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import App from './App';
import { initializeLocalEnvVars } from './setupLocalEnvVars';

const devAmbiente =
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test';

if (devAmbiente) {
    initializeLocalEnvVars();
}

initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
