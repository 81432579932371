/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Body = styled.div`
    width: 90%;
    height: 84%;
    margin: auto;
`;

export const WelcomeText = styled.p<{ screenPixelRatio: number }>`
    font-size: ${props => (props.screenPixelRatio >= 1.5 ? '2' : '3')}rem;
    font-weight: 400;
    color: #e27800;
    margin: 20px 0;
`;

export const Text = styled.p<{ screenPixelRatio: number }>`
    font-size: ${props => (props.screenPixelRatio >= 1.5 ? '1.5' : '2')}rem;
    font-weight: 400;
    color: #636363;
    margin: 20px 0;
`;

export const CardsContainer = styled.div`
    width: 50%;
    height: 80%;
    overflow: auto;
`;

export const SubMenusContainer = styled.div`
    width: 50%;
`;

export const SubMenuCard = styled.ul`
    width: 80%;
    height: 80%;
    margin: auto;
    border: 1px solid #e27800;
    border-radius: 10px;
`;

export const MenuContainer = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
`;

export const NoModuleContainer = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NoModuleText = styled.span`
    font-size: 2rem;
    text-align: center;
    color: #636363;
`;
