/* eslint-disable no-shadow */
import { IErrorAPI } from '../../Interfaces/API';
import { IModulo } from '../../Interfaces/IModulo';
import IUser from '../../Interfaces/IUser';

export enum InfoUsuarioTypes {
    OBTER_DADOS_USUARIO_REQUEST = '@InfoUsuario/OBTER_DADOS_USUARIO_REQUEST',
    OBTER_DADOS_USUARIO_SUCCESS = '@InfoUsuario/OBTER_DADOS_USUARIO_SUCCESS',
    OBTER_DADOS_USUARIO_ERROR = '@InfoUsuario/OBTER_DADOS_USUARIO_ERROR',

    OBTER_MODULOS_USUARIO_REQUEST = '@InfoUsuario/OBTER_MODULOS_USUARIO_REQUEST',
    OBTER_MODULOS_USUARIO_SUCCESS = '@InfoUsuario/OBTER_MODULOS_USUARIO_SUCCESS',
    OBTER_MODULOS_USUARIO_ERROR = '@InfoUsuario/OBTER_MODULOS_USUARIO_ERROR',
}

export interface IInfoUsuarioState {
    carregandoDadosUsuario: boolean;
    dadosUsuario: IUser | undefined;
    erroObterDadosUsuario: IErrorAPI | undefined;

    carregandoModulosUsuario: boolean;
    modulosUsuario: IModulo | undefined;
    erroObterModulosUsuario: IErrorAPI | undefined;
}
