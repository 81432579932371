import React from 'react';
import { ActionButton, Icon } from '@fluentui/react';
import { Container, IconImage, IconStylesGenerator, Text } from './styles';

interface IModulesProps {
    name: string;
    baseLink: string;
    isSelected: boolean;
    isActive: boolean;
    isNew: boolean;
    iconUrl: string;
    selectModulo: () => void;
    redirectModule?: boolean;
}

const ModuleCard: React.FC<IModulesProps> = ({
    name,
    baseLink,
    isSelected,
    isActive,
    isNew,
    iconUrl,
    selectModulo,
    redirectModule,
}: IModulesProps): React.ReactElement => {
    const screenPixelRatio = globalThis.devicePixelRatio;
    const redirectToUrl = (url: string) => {
        globalThis.location.href = `${url}`;
    };

    return (
        <Container
            isSelected={isSelected}
            isActive={isActive}
            onClick={() => {
                if (isActive) {
                    selectModulo();
                    if (redirectModule && baseLink) {
                        redirectToUrl(baseLink);
                    }
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    margin: `${screenPixelRatio >= 1.5 ? '5' : '10'}px`,
                    justifyContent: 'space-around',
                }}
            >
                <IconImage src={iconUrl} isSelected={isSelected} />
                <Text
                    screenPixelRatio={screenPixelRatio}
                    isSelected={isSelected}
                    isActive={isActive}
                >
                    {name}
                </Text>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: `${screenPixelRatio >= 1.5 ? '30' : '20'}%`,
                    justifyContent: `${isNew ? 'space-between' : 'flex-end'}`,
                }}
            >
                {isNew && isActive ? (
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <ActionButton
                            iconProps={{ iconName: 'AlertSolid' }}
                            disabled
                            styles={{
                                rootDisabled: {
                                    display: `${isSelected ? 'none' : 'flex'}`,
                                    height: '40%',
                                    border: `1px solid #e27800 !important`,
                                    color: 'white',
                                    backgroundColor: '#e27800',
                                    borderRadius: '15px',
                                    margin: 'auto',
                                    fontWeight: '600',
                                },
                                iconDisabled: {
                                    color: 'white',
                                },
                            }}
                        >
                            NOVO
                        </ActionButton>
                    </div>
                ) : (
                    <div />
                )}
                <div style={{ display: 'flex' }}>
                    <Icon
                        iconName="ChevronRight"
                        styles={{
                            ...IconStylesGenerator(
                                isSelected,
                                isActive,
                                screenPixelRatio,
                            ),
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

ModuleCard.defaultProps = {
    redirectModule: false,
};

export default ModuleCard;
