/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
`;
