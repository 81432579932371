/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { FontIcon, Icon } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { ISubMenus } from '../../Interfaces/IModulo';
import {
    Container,
    ItemList,
    List,
    ItemLink,
    NoModuleContainer,
    NoModuleText,
} from './styles';

import { GetToken } from '../../Storage/Services/utils.service';

interface ISubMenusProps {
    subMenus: ISubMenus[];
    baseUrl: string;
    moduloSelecionado: number;
    semModuloAtivo: string | undefined;
}

const SubModuleList: React.FC<ISubMenusProps> = ({
    subMenus,
    baseUrl,
    moduloSelecionado,
    semModuloAtivo,
}: ISubMenusProps): React.ReactElement => {
    const screenPixelRatio = globalThis.devicePixelRatio;
    const redirectToUrl = (url: string) => {
        globalThis.location.href = `${url}`;
    };

    function redirectToSubModule(urlModulo: string, subMenu: ISubMenus) {
        const { urlSubMenu } = subMenu;

        // Condição especifica para submenu de Portal de Notas Fiscais feito pela V360
        if (urlSubMenu?.toLocaleLowerCase()?.includes('virtual360')) {
            const token = GetToken();
            const url = `${urlSubMenu}?token=${token}`;

            redirectToUrl(url);
        }

        const url = `${urlModulo}${urlSubMenu}/`;
        redirectToUrl(url);
    }

    return (
        <Container
            moduloSelecionado={moduloSelecionado >= 0}
            semModuloAtivo={!!semModuloAtivo}
        >
            {!!semModuloAtivo || subMenus?.length <= 0 ? (
                <NoModuleContainer>
                    <NoModuleText>
                        {semModuloAtivo ??
                            'Nenhum submenu encontrado para este módulo. Favor entrar em contato com a CAPEMISA. Desculpe o transtorno. =)'}
                    </NoModuleText>
                </NoModuleContainer>
            ) : (
                <List>
                    {subMenus?.map(subMenu => (
                        <div
                            key={subMenu.ordemSubMenu}
                            style={{
                                display: 'flex',
                                margin: '15px 10px',
                                borderBottom: '1px solid #D1D1D1',
                            }}
                        >
                            <FontIcon
                                iconName="Remove"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#e27800',
                                    fontSize: '1rem',
                                    margin: '0 25px',
                                }}
                            />
                            <ItemList>
                                <ItemLink
                                    screenPixelRatio={screenPixelRatio}
                                    onClick={() =>
                                        redirectToSubModule(baseUrl, subMenu)
                                    }
                                >
                                    {subMenu?.nomeSubMenu}
                                </ItemLink>
                            </ItemList>
                        </div>
                    ))}
                </List>
            )}
        </Container>
    );
};

export default SubModuleList;
