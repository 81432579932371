/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
    IStyleFunctionOrObject,
    IIconStyleProps,
    IIconStyles,
} from '@fluentui/react';

export const Container = styled.div<{ isSelected: boolean; isActive: boolean }>`
    width: 98%;
    height: 15%;
    display: flex;
    padding: 0 15px;
    margin: 10px 0;
    justify-content: space-between;
    cursor: ${props => (props.isActive ? 'pointer' : 'not-allowed')};
    border: 2px solid ${props => (props.isActive ? '#e27800' : '#0000004A')};
    border-radius: 50px;
    background-color: ${props => (props.isSelected ? '#e27800' : '#fff')};

    &:hover {
        border: ${props =>
            props.isActive ? '3px solid #e27800' : '1px solid #0000004A'};
    }
`;

export const Text = styled.p<{
    isSelected: boolean;
    isActive: boolean;
    screenPixelRatio: number;
}>`
    color: ${props =>
        props.isSelected ? '#fff' : props.isActive ? '#e27800' : '#0000004A'};
    font-size: ${props => (props.screenPixelRatio >= 1.5 ? '1' : '1.5')}rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`;

export const IconImage = styled.img<{ isSelected: boolean }>`
    ${props => (props.isSelected ? 'filter: invert(1) brightness(2.5);' : '')}
`;

export const IconStylesGenerator = (
    isSelected: boolean,
    isActive: boolean,
    screenPixelRatio: number,
): IStyleFunctionOrObject<IIconStyleProps, IIconStyles> => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
            fontSize: `1.${screenPixelRatio >= 1.7 ? '3' : '5'}rem`,
            color: `${
                isSelected ? 'white' : isActive ? '#e27800' : '#0000004A'
            }`,
        },
    };
};
