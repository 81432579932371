/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable func-names */
import { AxiosResponse } from 'axios';
import { EventChannel, eventChannel } from 'redux-saga';
import { all, call, put, spawn, take, takeLatest } from 'redux-saga/effects';
import { IErrorAPI /* ,IResultAPI */ } from '../../Interfaces/API';
import { IModulo } from '../../Interfaces/IModulo';
import IUser from '../../Interfaces/IUser';
import {
    CapNegociosAPI,
    defaultUrlCapNegocios,
} from '../Services/CapNegocios.service';
import {
    defaultUrlPortalSorteado,
    PortalSorteadoAPI,
} from '../Services/PortalSorteado.service';
import { GetToken } from '../Services/utils.service';
import {
    obterDadosUsuarioError,
    obterDadosUsuarioSuccess,
    obterModulosUsuarioError,
    obterModulosUsuarioSuccess,
} from './actions';
import { InfoUsuarioTypes } from './reduxTypes';

export const redicionarParaPaginaDeLogin = (): void => {
    const urlB2c = sessionStorage.getItem('CAPNEGOCIOS_ENV_URL_B2C_LOGIN');
    sessionStorage.clear();
    globalThis.location.href = urlB2c ?? process.env.REACT_APP_URL_B2C_LOGIN;
};

type EventChannerReturn = {
    estaAutenticado: boolean;
    dadosUsuario: IUser | undefined;
};

export function* obterDadosUsuarioSaga() {
    try {
        const url = `${defaultUrlPortalSorteado()}/usuario`;
        const response: AxiosResponse<IUser> = yield call(
            PortalSorteadoAPI.get,
            url,
        );
        if (response.status === 401) {
            redicionarParaPaginaDeLogin();
        }
        yield put(obterDadosUsuarioSuccess(response.data as IUser));
    } catch (e) {
        yield put(obterDadosUsuarioError(e.data as IErrorAPI));
    }
}

export function* obterModulosUsuarioSaga() {
    try {
        const url = `${defaultUrlCapNegocios()}/modulo/acesso`;
        const response: AxiosResponse<IModulo> = yield call(
            CapNegociosAPI.get,
            url,
        );
        if (response.status === 401) {
            redicionarParaPaginaDeLogin();
        }
        yield put(obterModulosUsuarioSuccess(response.data as IModulo));
    } catch (e) {
        yield put(obterModulosUsuarioError(e.data as IErrorAPI));
    }
}

const getVerificaAutenticacaoChannel = () => {
    return eventChannel<EventChannerReturn>(emitter => {
        const intervalFn = (): void => {
            PortalSorteadoAPI.get(`${defaultUrlPortalSorteado()}/usuario`)
                .then(e => {
                    if (e.status === 401) {
                        emitter({
                            estaAutenticado: false,
                            dadosUsuario: undefined,
                        });
                    } else {
                        emitter({
                            estaAutenticado: true,
                            dadosUsuario: e.data as IUser,
                        });
                    }
                })
                .catch(() => {
                    emitter({
                        estaAutenticado: false,
                        dadosUsuario: undefined,
                    });
                });
        };

        const immediateId = setImmediate?.(intervalFn); // primeira ação executada sem delay
        const intevalId = setInterval(
            intervalFn,
            10 /* qtd mins */ * 60 * 1000,
        ); // intervalo para executar a ação

        return () => {
            clearInterval(intevalId);
            clearImmediate?.(immediateId);
        };
    });
};

function* obterTokenSaga() {
    const verificaAutenticacaoChannel: EventChannel<EventChannerReturn> =
        yield call(getVerificaAutenticacaoChannel);

    while (true) {
        const retornoAutenticacao: EventChannerReturn = yield take(
            verificaAutenticacaoChannel,
        );

        if (retornoAutenticacao.estaAutenticado) {
            // se usuario esta autenticado, vida que segue
            sessionStorage.setItem('TOKEN', GetToken());
            sessionStorage.setItem(
                'listaPracas',
                JSON.stringify(retornoAutenticacao.dadosUsuario?.listaPraca),
            );
            yield put(
                obterDadosUsuarioSuccess(retornoAutenticacao.dadosUsuario!),
            );
            yield call(obterModulosUsuarioSaga);
        } else {
            // se não encontrar o token redirecionar para o B2C
            yield call(redicionarParaPaginaDeLogin);
        }
    }
}

export default function* () {
    yield spawn(obterTokenSaga);

    yield all([
        takeLatest(
            InfoUsuarioTypes.OBTER_DADOS_USUARIO_REQUEST,
            obterDadosUsuarioSaga,
        ),
        takeLatest(
            InfoUsuarioTypes.OBTER_MODULOS_USUARIO_REQUEST,
            obterModulosUsuarioSaga,
        ),
    ]);
}
