import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import rootReducer, { AppState } from './rootReducer';
import rootSaga from './rootSaga';

// const middlewares = [];

// const sagaMiddleware = createSagaMiddleware();

// middlewares.push(sagaMiddleware);
// middlewares.push(logger);

// const store: Store<AppState> = createStore(
//     rootReducer,
//     applyMiddleware(...middlewares),
// );

// sagaMiddleware.run(rootSaga);

// export default store;

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);
middlewares.push(logger);

const store: Store<AppState> = createStore(
    rootReducer,
    applyMiddleware(...middlewares),
);

sagaMiddleware.run(rootSaga);

export default store;
