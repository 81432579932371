import { useSelector } from 'react-redux';
import { combineReducers } from 'redux';

import User from './User';

import { IErrorAPI } from '../Interfaces/API';
import { IInfoUsuarioState } from './User/reduxTypes';

export interface IReduxStateObject<DataType> {
    data: DataType;
    carregando: boolean;
    error: IErrorAPI;
}

export interface AppState {
    User: IInfoUsuarioState;
}

export interface IAction<T> {
    type: string;
    payload: Partial<T>;
}

export default combineReducers({
    User,
});

export const useTypedSelector = <T>(
    selector: (store: AppState) => T,
    qualityFn?: (left: T, right: T) => boolean,
): T => useSelector<AppState, T>(selector, qualityFn);
