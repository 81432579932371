import styled from 'styled-components';

export const Container = styled.div<{
    moduloSelecionado: boolean;
    semModuloAtivo: boolean;
}>`
    width: 80%;
    height: 80%;
    border: 2px solid #e27800;
    border-radius: 10px;
    margin: auto;
    opacity: ${props => (props.semModuloAtivo ? '1' : '0')};
    transition: opacity 0.5s;

    ${props => (props.moduloSelecionado ? 'opacity: 1;' : '')}
`;

export const List = styled.ul`
    width: 98%;
    height: 98%;
    margin: 5px auto;
    overflow: auto;
    list-style: none;
`;

export const ItemList = styled.li`
    margin: 15px 5px;
`;

export const ItemLink = styled.a<{ screenPixelRatio: number }>`
    font-size: ${props => (props.screenPixelRatio >= 1.5 ? '1' : '1.5')}rem;
    font-weight: 600;
    white-space: nowrap;
    color: #707070;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #e27800;
    }
`;

export const NoModuleContainer = styled.div`
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NoModuleText = styled.span`
    font-size: 2.5rem;
    text-align: center;
    color: #636363;
`;
