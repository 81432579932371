import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../Storage/rootReducer';
import { Container } from './styles';

const Auth: React.FC = () => {
    const history = useHistory();
    const modulosUsuario = useTypedSelector(store => store.User.modulosUsuario);
    const forgotPassword = globalThis.location.hash.includes('AADB2C90118');

    if (forgotPassword) {
        globalThis.location.href =
            sessionStorage.getItem('CAPNEGOCIOS_ENV_URL_B2C_ESQUECI_SENHA') ??
            '';
    }

    React.useEffect(() => {
        if (modulosUsuario) {
            history.push('/inicio');
        }
    }, [history, modulosUsuario]);

    return (
        <Container>
            <Spinner
                label="Carregando dados..."
                size={SpinnerSize.large}
                styles={{
                    circle: {
                        borderColor: '#e27800 #fff #fff',
                        borderWidth: '2.5px',
                        width: '2rem',
                        height: '2rem',
                    },
                    label: { fontSize: '2rem', color: '#e27800' },
                }}
            />
        </Container>
    );
};

export default Auth;
