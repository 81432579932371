import { Reducer } from 'redux';
import { IInfoUsuarioState, InfoUsuarioTypes } from './reduxTypes';

const initialState: IInfoUsuarioState = {
    carregandoDadosUsuario: false,
    dadosUsuario: undefined,
    erroObterDadosUsuario: undefined,

    carregandoModulosUsuario: false,
    modulosUsuario: undefined,
    erroObterModulosUsuario: undefined,
};

const reducer: Reducer<IInfoUsuarioState> = (state = initialState, action) => {
    switch (action.type) {
        case InfoUsuarioTypes.OBTER_DADOS_USUARIO_REQUEST:
            return {
                ...state,
                carregandoDadosUsuario: true,
                erroObterDadosUsuario: undefined,
            };

        case InfoUsuarioTypes.OBTER_DADOS_USUARIO_SUCCESS:
            return {
                ...state,
                carregandoDadosUsuario: false,
                erroObterDadosUsuario: undefined,
                dadosUsuario: action.payload,
            };

        case InfoUsuarioTypes.OBTER_DADOS_USUARIO_ERROR:
            return {
                ...state,
                carregandoDadosUsuario: false,
                erroObterDadosUsuario: action.payload,
            };

        case InfoUsuarioTypes.OBTER_MODULOS_USUARIO_REQUEST:
            return {
                ...state,
                carregandoModulosUsuario: true,
                erroObterModulosUsuario: undefined,
            };

        case InfoUsuarioTypes.OBTER_MODULOS_USUARIO_SUCCESS:
            return {
                ...state,
                carregandoModulosUsuario: false,
                erroObterModulosUsuario: undefined,
                modulosUsuario: action.payload,
            };

        case InfoUsuarioTypes.OBTER_MODULOS_USUARIO_ERROR:
            return {
                ...state,
                carregandoModulosUsuario: false,
                erroObterModulosUsuario: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
