import { IDropdownStyles } from '@fluentui/react';
import styled from 'styled-components';

const screenPixelRatio = globalThis.devicePixelRatio;

export const Container = styled.div`
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0px;
    border-bottom: 1px solid #707070;
`;

export const LogosContainer = styled.div<{ screenPixelRatio: number }>`
    display: flex;
    width: ${props => (props.screenPixelRatio >= 1.5 ? '250' : '360')}px;
    height: 100%;
    margin: 0px 80px;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.img<{ height: string }>`
    height: ${props => props.height};
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    margin: 0 5%;
    align-items: center;
`;

export const InfoUsuarioContainer = styled.div`
    display: flex;
    align-items: center;
    color: #e27800;
    font-size: 1.5rem;
`;

export const InfoUsuario = styled.b`
    margin-left: 5px;
    font-weight: bold;
    font-size: 1.5rem;
`;

export const DropdownStyles: Partial<IDropdownStyles> = {
    root: {
        display: 'flex',
    },
    dropdown: {
        margin: 'auto',
    },
    title: {
        borderColor: '#e27a04',
        borderRadius: 'none',
        minWidth: '215px',
        fontSize: '15px',
        height: `${screenPixelRatio >= 1.5 ? '20' : '30'}px`,
        lineHeight: '20px',
        verticalAlign: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    callout: {
        selectors: {
            '& > .ms-Callout-main': {
                height: '50vh',
            },
        },
    },
    caretDown: {
        color: '#e27a04',
        fontSize: '15px',
    },
    caretDownWrapper: {
        lineHeight: '12px',
        top: `${screenPixelRatio >= 1.5 ? '5' : '10'}px`,
    },
};
