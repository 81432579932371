import React from 'react';
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom';

import Home from '../Pages/Home';
import Auth from '../Pages/Auth';

export default function Routes(): React.ReactElement {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={() => <Auth />} />
                <Route exact path="/inicio" component={() => <Home />} />
            </Switch>
        </Router>
    );
}
