import React from 'react';
import {
    ActionButton,
    Dropdown,
    IDropdownOption,
    Label,
} from '@fluentui/react';

import {
    Container,
    DropdownStyles,
    InfoContainer,
    InfoUsuario,
    InfoUsuarioContainer,
    Logo,
    LogosContainer,
} from './styles';
import { useTypedSelector } from '../../Storage/rootReducer';
import { redicionarParaPaginaDeLogin } from '../../Storage/User/saga';
import { IListaPraca } from '../../Interfaces/IUser';

const Header: React.FC = (): React.ReactElement => {
    const screenPixelRatio = globalThis.devicePixelRatio;
    const usuario = useTypedSelector(store => store.User.dadosUsuario);
    const urlCdn =
        sessionStorage.getItem('CAPNEGOCIOS_ENV_URL_CDN_IMG') ??
        process.env.REACT_APP_URL_IMG;

    const getListaProdutosSession = JSON.parse(
        sessionStorage.getItem('listaPracas') as string,
    ) as IListaPraca[] | undefined;

    const listaProdutosSession =
        getListaProdutosSession === [] ? undefined : getListaProdutosSession;

    const produtosOptions: IDropdownOption[] =
        (listaProdutosSession ?? usuario?.listaPraca)
            ?.slice()
            .sort((a, b) => {
                if (a.nome < b.nome) {
                    return -1;
                }
                if (a.nome > b.nome) {
                    return 1;
                }
                return 0;
            })
            // ?.filter(e => e.lstPracaCliente && e.lstPracaCliente[0])
            ?.map(produto => ({
                key: produto?.id,
                text: produto?.nome,
            })) ?? [];

    const pracaSelecionadaIdSession =
        sessionStorage.getItem('pracaSelecionadaId') === ''
            ? undefined
            : sessionStorage.getItem('pracaSelecionadaId');

    const pracaSelecionadaNomeSession =
        sessionStorage.getItem('pracaSelecionadaNome') === ''
            ? undefined
            : sessionStorage.getItem('pracaSelecionadaNome');

    const pracaSelecionadaModalidadeSession =
        sessionStorage.getItem('pracaSelecionadaModalidade') === ''
            ? undefined
            : sessionStorage.getItem('pracaSelecionadaModalidade');

    const [produtoSelecionadoKey, setProdutoSelecionadoKey] = React.useState(
        Number(pracaSelecionadaIdSession ?? usuario?.listaPraca?.[0]?.id) ?? -1,
    );

    React.useEffect(() => {
        if (produtoSelecionadoKey > 0) {
            sessionStorage.setItem(
                'pracaSelecionadaId',
                pracaSelecionadaIdSession ??
                    produtoSelecionadoKey?.toString() ??
                    '',
            );
            sessionStorage.setItem(
                'pracaSelecionadaNome',
                pracaSelecionadaNomeSession ??
                    usuario?.listaPraca.find(
                        item => item.id === produtoSelecionadoKey,
                    )?.nome ??
                    '',
            );
            sessionStorage.setItem(
                'pracaSelecionadaModalidade',
                pracaSelecionadaModalidadeSession ??
                    usuario?.listaPraca
                        .find(item => item.id === produtoSelecionadoKey)
                        ?.idRefModalidade.toString() ??
                    '',
            );
        }
    }, [produtoSelecionadoKey, usuario?.listaPraca]);

    return (
        <Container>
            <LogosContainer screenPixelRatio={screenPixelRatio}>
                <div>
                    <Logo src={`${urlCdn}/logo_capemisa.svg`} height="100%" />
                </div>
                <div>
                    <Logo src={`${urlCdn}/logo_capnegocios.svg`} height="55%" />
                </div>
            </LogosContainer>
            <InfoContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <InfoUsuarioContainer>
                        Olá,
                        <InfoUsuario>{usuario?.nome}</InfoUsuario>
                    </InfoUsuarioContainer>
                    <ActionButton
                        text="(sair)"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => {
                            redicionarParaPaginaDeLogin();
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <Label styles={{ root: { padding: '5px 5px 5px 0' } }}>
                        PRODUTO:
                    </Label>
                    {produtosOptions.length > 1 ? (
                        <Dropdown
                            styles={{ ...DropdownStyles }}
                            options={produtosOptions}
                            selectedKey={produtoSelecionadoKey}
                            onChange={(_, newValue) => {
                                const produtoSelecionado =
                                    usuario?.listaPraca.find(
                                        item =>
                                            item.id === Number(newValue?.key),
                                    );
                                setProdutoSelecionadoKey(
                                    Number(newValue?.key) ?? -1,
                                );
                                sessionStorage.setItem(
                                    'pracaSelecionadaId',
                                    produtoSelecionado?.id.toString() ?? '',
                                );
                                sessionStorage.setItem(
                                    'pracaSelecionadaNome',
                                    produtoSelecionado?.nome ?? '',
                                );
                                sessionStorage.setItem(
                                    'pracaSelecionadaModalidade',
                                    produtoSelecionado?.idRefModalidade.toString() ??
                                        '',
                                );
                            }}
                        />
                    ) : (
                        <span>{produtosOptions?.[0]?.text}</span>
                    )}
                </div>
            </InfoContainer>
        </Container>
    );
};

export default Header;
