/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable default-case */

import { action } from 'typesafe-actions';

import IUser from '../../Interfaces/IUser';
import { IErrorAPI } from '../../Interfaces/API';

import { InfoUsuarioTypes } from './reduxTypes';
import { IModulo } from '../../Interfaces/IModulo';

export const obterDadosUsuarioRequest = () =>
    action(InfoUsuarioTypes.OBTER_DADOS_USUARIO_REQUEST);

export const obterDadosUsuarioSuccess = (response: IUser) =>
    action(InfoUsuarioTypes.OBTER_DADOS_USUARIO_SUCCESS, response);

export const obterDadosUsuarioError = (erro: IErrorAPI) =>
    action(InfoUsuarioTypes.OBTER_DADOS_USUARIO_ERROR, erro);

export const obterModulosUsuarioRequest = () =>
    action(InfoUsuarioTypes.OBTER_MODULOS_USUARIO_REQUEST);

export const obterModulosUsuarioSuccess = (response: IModulo) =>
    action(InfoUsuarioTypes.OBTER_MODULOS_USUARIO_SUCCESS, response);

export const obterModulosUsuarioError = (erro: IErrorAPI) =>
    action(InfoUsuarioTypes.OBTER_MODULOS_USUARIO_ERROR, erro);
